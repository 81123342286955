import { Text, Paper, ActionIcon, Group, useMantineColorScheme } from '@mantine/core';
import { Card, Image, Badge, Button } from '@mantine/core';
import { IconSun, IconMoonStars } from '@tabler/icons';
import posthog from 'posthog-js';
import {useEffect, useState} from 'react';

export function NoteCard(props) {
    useEffect(() => {
        posthog.init(process.env.NEXT_PUBLIC_POSTHOG_API_KEY, { api_host: 'https://app.posthog.com' });
    }, []);


    {/* log click and go to link */}
    
    function handleClick(link) {
        window.open(link, '_blank');
    }


    return (
        <Card shadow="xl" p="lg" radius="md" withBorder onClick={() => handleClick("/notes/" + props.uuid)}>
            {/*<Card.Section>
                <Image
                    src={props.image}
                    height={160}
                    fit="contain"
                    alt="Norway"
                />
            </Card.Section> */}
            <div className="font-gilroy-regular"
                style={{wordWrap: "break-word",
                        whiteSpace: "pre-wrap",
                        wordBreak: "break-word"}}>
            {/* {props.title != props.text && <Text weight={500}>{props.title}</Text>} */}
                
                <div className=" pt-2 pb-2 text-md md:text-lg text-gray-700 font-gilroy-regular leading-[1.75] " style={{whiteSpace: 'pre-wrap'}}>
                    {/* <div dangerouslySetInnerHTML={{ 
                        __html: markdownToHtml(props.message)
                    }} /> */}
                    {props.text}
                </div>
                <div className=" text-xs md:text-xs text-gray-300 font-gilroy-regular leading-[1.75] " style={{whiteSpace: 'pre-wrap'}}>
                    {/* <div dangerouslySetInnerHTML={{ 
                        __html: markdownToHtml(props.message)
                    }} /> */}
                    {props.time}
                </div>
                {/* if title == text, don't show title */}
                
                {/*<Badge color="pink" variant="light">
                On Sale
                </Badge>*/}
            {/* <Text size="sm" c="dimmed">
                {props.time}
            </Text> */}
            </div>
        </Card>
  );
}

