import Head from 'next/head';
import dynamic from 'next/dynamic'
import axios from "axios";
import React, { useState, useEffect } from 'react';
import { Text, Button, TextInput, LoadingOverlay, Select, Collapse} from '@mantine/core';
import ReactMarkdown from 'react-markdown'
import styles from "../styles/Search.module.css";
import Navbar from '../components/Navbar';
import { ChatPane } from '../components/ChatPane';
import { ScratchPane } from '../components/ScratchPane';
import { Grid } from '@mantine/core';
import { NotesPane } from '../components/notes/NotesPane';
import { NoteAddBox } from '../components/notes/NoteAddBox';
import withAuth from '../hoc/withAuth';

export default withAuth( function Notes(props: any) {
    const [currentRecs, setCurrentRecs] = useState("")

    return (
    <>
    <div className={styles.padsir} >

    <div style={{display: 'block'}}>
    {/*<HeaderResponsive / >*/}
    </div>
    <div className={styles.form} style={{textAlign: "center"}}>
    <NoteAddBox />
    </div>
    <NotesPane />
    </div>
  </>
  )


})
