import { Text, ActionIcon, Group, useMantineColorScheme, Grid } from '@mantine/core';
import { IconSun, IconMoonStars, IconCaretRight, IconCaretLeft} from '@tabler/icons';
import axios from "axios";
import { NoteCard } from './NoteCard';
import { Subgrid } from './Subgrid';
{/*import { FeedbackIcon } from './FeedbackIcon'*/};
import { useState, useEffect } from 'react';
import styles from "../../styles/Search.module.css";
//import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"
import { Masonry } from 'react-plock';

export function NotesPane(props: any) {
    /*const { colorScheme, toggleColorScheme } = useMantineColorScheme(); */
    const [notes, setNotes] = useState([]);
    const [page, setPage] = useState(1);



    async function handleNext() {
        setPage(page + 1);
    }


    async function handlePrev() {
        setPage(page - 1);
    }

    //function reorder(notes, columns): any[] {
    //    const cols = columns;
    //    let newNotes = [];
    //    let col = 0;
    //    while (col < cols) {
    //        for (let i = 0; i < notes.length; i+=cols) {
    //            let _val = notes[i + col];
    //            if (_val) {
    //                newNotes.push(_val);
    //            }
    //            col++;
    //        }

    //    for (let i = 0; i < notes.length; i++) {
    //        newNotes.push(notes[i]);
    //    }
    //    return newNotes;
    //}
    //}

    async function getNotes(page) {
        let res = await axios.post('api/notes', { page: page })
            .then((response) => {
                console.log(response.data)
                setNotes(response.data.res);
            }
        )
            .catch((error) => {
                console.log(error);
            }
        );

    }
 
    useEffect(() => {
        getNotes(page);
    }, []);


    useEffect(() => {
        getNotes(page);
    }, [page]);


    //useEffect(() => {
    //}, [props.recs]);

    return (

    <>
    {/* Toggle between grid view and list view */}
    <div className={styles.form} style={{textAlign: "center"}}>
        <Text>Notes</Text>
    </div>
    {/*<ResponsiveMasonry
        columnsCountBreakPoints={{350: 1, 750: 2, 900: 3}}
        
    >
    <Masonry gutter="10px"> */}
    <Masonry
      items={notes}
      config={{
        columns: [1, 2, 3],
        gap: [24, 12, 6],
        media: [640, 768, 1024],
      }}
      render={(item, idx) => (
            <NoteCard item={item}
                            title={item.title}
                            text={item.text}
                            time={item.updated}
                            link={item.link}
                            uuid={item.uuid}
                            />
      )}
    />
    {/*</Masonry>
    </ResponsiveMasonry> */}
    {/* arrows for pagination */}
    <Group position="center" >


    <ActionIcon 
        style={{justifyContent:"flex-end"}}
        color="blue"
        size="lg"
        onClick={handlePrev}
    >
        <IconCaretLeft size={40} />
    </ActionIcon>

    <ActionIcon 
        style={{justifyContent:"flex-end"}}
        color="blue"
        size="lg"
        onClick={handleNext}
    >
        <IconCaretRight size={40} />
    </ActionIcon>

    </Group>

    </>
  );
}
