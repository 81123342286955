import React from 'react';
import { useRouter } from 'next/router';

const withAuth = (WrappedComponent) => {
  return (props) => {
    const Router = useRouter();
    const [auth, setAuth] = React.useState({
      loading: true,
      authenticated: false,
    });

    React.useEffect(() => {
      fetch('/api/auth-check', {
        credentials: 'include',
      })
        .then(response => response.json())
        .then(data => {
          if (data.authenticated) {
            setAuth({ loading: false, authenticated: true });
          } else {
            Router.replace('/login');
          }
        });
    }, []);

    if (auth.loading || !auth.authenticated) {
      return null;
    }

    return <WrappedComponent {...props} />;
  };
};

export default withAuth;
