import { Textarea, Grid, ActionIcon, Group, useMantineColorScheme } from '@mantine/core';
import {useEffect, useState} from 'react';
import axios from "axios";
import { Send, Refresh } from 'tabler-icons-react';
import { Space } from '@mantine/core';
import styles from "../../styles/Search.module.css";
import { BetterButton } from '../Buttons';
import { useRef } from 'react';

export function NoteAddBox(props) {
    const [title, setTitle] = useState("");
    const [text, setText] = useState("");
    const textAreaRef = useRef(null);

    useEffect(() => {
        const textarea = textAreaRef.current;
        if (textarea) {
            textarea.addEventListener('input', () => autoExpand(textarea));
            return () => {  // Cleanup: remove event listener when component is unmounted
                textarea.removeEventListener('input', () => autoExpand(textarea));
            };
        }
    }, []);  // Empty dependency array: run effect once, similar to componentDidMount



    async function submitAdd() {
        let response = await axios.post(`api/notes/create`, 
        { title: title, text: text })
            .then(res => res)
            .catch(err => err.response);
        //let response = {status: 200, data: {res: {answer: 'testans'}}}
    
        if (response.status < 300) {
                //for (let i = 0; i < response.data.res.docs.length; i++) {
                //    let val = response.data.res.docs[i]
                //    total.push([val['title'], val['text'], val['link']])
                //    ts.push(val["title"])
                //}
            location.reload();
        } else {
            // alert("Something went wrong")
            console.log(response.data)
        }
    }
//useEffect(() => {
    //    posthog.init(process.env.NEXT_PUBLIC_POSTHOG_API_KEY, { api_host: 'https://app.posthog.com' });
//    }, []);

    function handleChange(event: any) {
        setText(event.target.value)
        setTitle(event.target.value)
    }

    function autoExpand(textarea) {
        textarea.style.height = 'auto';  // Reset height to auto before calculating the scroll height
        textarea.style.height = textarea.scrollHeight + 'px';
    }
    


    function handleKeyDown(event: any) {
       //handle shift + enter
        if (event.shiftKey && event.key === 'Enter') {
            setText("")
            setTitle("")
            submitAdd()
        }
    }

    function handleClick(event: any) {
        setText("")
        setTitle("")
        submitAdd()
    }


    {/* log click and go to link */}
    
    /* function handleClick(link) {
        console.log('clicked');
        posthog.capture('product click', { product: link})
        window.open(link, '_blank');
    } */


    return (
        <>
        <div className={styles.container}>

        <Space h="xl" />
        <div className='flex space-x-2 rounded-xl -mx-2'>

            <div className='grow'>
            <div className="flex space-x-2 rounded-xl border-2 border-blueshadow bg-gray-50 p-2 sm:p-3 overflow-visible">
                <textarea
                ref={textAreaRef}
                value={props.query}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                placeholder=""
                className="h-auto resize-none grow bg-transparent text-sm sm:text-lg text-gray-900 focus:outline-none font-gilroy-regular overflow-grow"
                />
                <div className="justify-end self-center">
                <ActionIcon variant="transparent" onClick={handleClick} className='justify-self-end'>
                    <Send
                    style={{ color: '#0077b6'}}
                    size={40}
                    color='#0077b6'
                    />
                </ActionIcon>
                </div>
            </div>
            </div>
        </div>
            {/* <Grid>
            <Grid.Col span={11}>

            <Textarea 
                styles={{rightSection: { pointerEvents: 'none' } }}
                value={text}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                radius="lg"
                placeholder="Add a note"
                autosize
            />
            </Grid.Col>
            <Grid.Col span={1}>
            <ActionIcon
                style={{justifyContent:"flex-end"}}
                color="blue"
                variant="filled"
                size="lg"
                onClick={handleClick}
            >
                <IconSend  size={40} />
            </ActionIcon>
            </Grid.Col>
        </Grid> */}
        </div>


        </>
  );
}

